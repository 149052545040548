export const  productSize = {
    Small: 1,
    Medium: 2,
    Large: 3,
}

export const productSizesMap = {
    [productSize.Small]: 'Small',
    [productSize.Medium]: 'Med',
    [productSize.Large]: 'Large',
}

