import GrandDeluxeImage from '../images/Products/Deluxe_7x5.jpg';
import BigDogImage from '../images/Products/BigDog_7x5.jpg';
import SmallDogImage from '../images/Products/SmallDog_7x5.jpg';
import WorkingHarnessImage from '../images/Products/harness.jpg';
import ReplacementBeltImage from '../images/Products/replacement_belt.jpg';

export const productIDs = {
  GrandDeluxe: 0,
  BigDog: 1,
  SmallDog: 2,
  WorkingHarness: 3,
  SmallDogReplacementBelt: 4,
  GrandDeluxeReplacementBelt: 5,
  BigDogReplacementBelt: 6,
};

export const getItemTotal = (price, quantity, shipping) => {
  if (!isNaN(price) && !isNaN(quantity) && !isNaN(shipping)) {
    return (price * quantity) + (shipping * quantity);
  }
  return 0;
};

export const getProductList = () => {
  const ProductList = {
    GrandDeluxe: {
      name: 'Grand Deluxe Model Treadmill',
      price: 475.00,
      height: '30"',
      width: '20"',
      beltLength: '115"',
      beltWidth: '15"',
      weight: '70 lbs',
      length: '60"',
      image: GrandDeluxeImage,
      quantity: 1,
      description: 'Grand Deluxe Model is our most popular model. Its well suited for dogs between 45 and 75 pounds of all breeds.',
      shipping: 89.00,
      isTreadmill: true,
      ProductID: productIDs.GrandDeluxe,
    },
    BigDog: {
      name: 'Big Dog Model Treadmill',
      price: 495.00,
      height: '34"',
      width: '20"',
      beltLength: '127"',
      beltWidth: '15"',
      weight: '77 lbs',
      length: '66"',
      image: BigDogImage,
      quantity: 1,
      description: 'Big Dog Mill model is perfect for dogs between 80 and 100 pounds of all breeds.',
      shipping: 99.00,
      isTreadmill: true,
      ProductID: productIDs.BigDog,
    },
    SmallDog: {
      name: 'Small Dog Model Treadmill',
      price: 465.00,
      height: '28"',
      width: '20"',
      beltLength: '103"',
      beltWidth: '15"',
      weight: '68 lbs',
      length: '54"',
      image: SmallDogImage,
      quantity: 1,
      description: 'Our Small Dog Mill Model is recommended for dogs under 45 pounds of all breeds.',
      shipping: 79.00,
      isTreadmill: true,
      ProductID: productIDs.SmallDog,
    },
    WorkingHarness: {
      name: '5-Point Working Harness',
      price: 70.00,
      image: WorkingHarnessImage,
      quantity: 1,
      description: 'These harnesses are heavily padded for your dogs comfort, and can be adjusted to fit all dogs perfectly!',
      shipping: 19.95,
      isTreadmill: false,
      ProductID: productIDs.WorkingHarness,
    },
    SmallDogReplacementBelt: {
      name: 'Small Dog Replacement Belt',
      price: 70.00,
      image: ReplacementBeltImage,
      quantity: 1,
      description: 'A replacement belt for the Small Dog Treadmill.',
      shipping: 22.95,
      isTreadmill: false,
      ProductID: productIDs.SmallDogReplacementBelt,
    },
    GrandDeluxeReplacementBelt: {
      name: 'Grand Deluxe Replacement Belt',
      price: 70.00,
      image: ReplacementBeltImage,
      quantity: 1,
      description: 'A replacement belt for the Grand Deluxe Treadmill.',
      shipping: 22.95,
      isTreadmill: false,
      ProductID: productIDs.GrandDeluxeReplacementBelt,
    },
    BigDogReplacementBelt: {
      name: 'Big Dog Replacement Belt',
      price: 75.00,
      image: ReplacementBeltImage,
      quantity: 1,
      description: 'A replacement belt for the Big Dog Treadmill.',
      shipping: 22.95,
      isTreadmill: false,
      ProductID: productIDs.BigDogReplacementBelt,
    },
  };
  return ProductList;
};