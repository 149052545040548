import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Grand Carpet Mill",
    "url": "http://www.grandcarpetmill.com",
    "contactPoint": [
      {
        "@type": "ContactPoint",
        "telephone": "+1-734-847-3759",
        "contactType": "Customer Service",
        "email": "piston111@msn.com"
      }
    ]
  };

  return (
  <div id="contact-us-wrapper" className="ht-100">
      <Helmet>
        <title>Contact Us | Grand Carpet Mill</title>
        <meta name="description" content="Have a question? We'd be happy to hear from you! Contact Grand Carpet Mill for inquiries about our products or more information." />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
    <Row className="justify-content-md-center">
      <Col lg="10" md="12" sm="12">
        <Card id="card-fill">
          <Card.Header>
            <h5 className="text-cursive pl-3 pt-1 my-0">
              Have a question? We'd be happy to hear from you!
            </h5>
          </Card.Header>
          <Card.Body>
            <p>
              &emsp;If you have any questions about any of our products
              or would just like to know a little bit more about us, feel free to
              email us at
              <a href="mailto:piston111@msn.com"> piston111@msn.com</a>.
              <br />
              Call us today with any questions about your canine carpet mill!&nbsp;
              <a href="tel:7348473759">(734)-847-3759</a>
            </p>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </div>
);
}
export default ContactUs;