import React, { useState } from 'react';
import {
  Navbar, Nav, NavDropdown, Row, Col, Button,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { makeStyles, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles({
  cart: {
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  },
});

const NavBar = () => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:800px)');
  const [expanded, setExpanded] = useState(false);

  const handleNavItemClick = () => {
    setExpanded(false);
  };

  return (
    <div>
      <Row className="mt-1">
        <Col lg="8">
          <h5 className="text-left app-title">Grand Carpet Mill Canine Treadmills</h5>
        </Col>
        <Col lg="4">
          <div className="text-right">
            <LinkContainer to="Checkout">
              <Button id="cart-btn" className="mr-4 px-3">Cart</Button>
            </LinkContainer>
          </div>
        </Col>
      </Row>
      <div className="app-header bg-burlywood mb-1 mt-1">
        <Row className="mx-auto pl-5" style={{marginBottom: '1vh'}}>
          <Navbar
            bg="none"
            expand="lg"
            className="w-100 company-row"
            expanded={expanded}
            onToggle={() => setExpanded(!expanded)}
          >
            <LinkContainer to="Checkout">
              <Navbar.Brand>
                <ShoppingCartIcon className={classes.cart} fontSize="large" />
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <LinkContainer to="Home">
                  <Nav.Link onClick={handleNavItemClick}>Home</Nav.Link>
                </LinkContainer>
                <NavDropdown title="Products" id="products-dropdown">
                  <LinkContainer to="GrandDeluxe">
                    <NavDropdown.Item onClick={handleNavItemClick}>Grand Deluxe Treadmill</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="BigDog">
                    <NavDropdown.Item onClick={handleNavItemClick}>Big Dog Treadmill</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="SmallDog">
                    <NavDropdown.Item onClick={handleNavItemClick}>Small Dog Treadmill</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="WorkingHarness">
                    <NavDropdown.Item onClick={handleNavItemClick}>Working Harness</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
                <NavDropdown title="Replacement Belts" id="belts-dropdown">
                  <LinkContainer to="SmallDogReplacementBelts">
                    <NavDropdown.Item onClick={handleNavItemClick}>Small Dog Replacement Belts</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="GrandDeluxeReplacementBelts">
                    <NavDropdown.Item onClick={handleNavItemClick}>Grand Deluxe Replacement Belts</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="BigDogReplacementBelts">
                    <NavDropdown.Item onClick={handleNavItemClick}>Big Dog Replacement Belts</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
                <LinkContainer to="FAQ">
                  <Nav.Link onClick={handleNavItemClick}>FAQ</Nav.Link>
                </LinkContainer>
                <NavDropdown title="About Us" id="about-dropdown">
                  <LinkContainer to="MissionStatement">
                    <NavDropdown.Item onClick={handleNavItemClick}>Our Mission</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="Testimonials">
                    <NavDropdown.Item onClick={handleNavItemClick}>Testimonials</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="ContactUs">
                    <NavDropdown.Item onClick={handleNavItemClick}>Contact Us</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Row>
      </div>
    </div>
  );
};

export default NavBar;