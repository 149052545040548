import React from 'react';
import { Row, Col, Card, Accordion, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
// TODO: This doesn't look the greatest
export const FAQ = () => {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Is a warranty included with my mill?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We make furniture quality mills - just like the furniture in your living room! As you would expect of quality furniture, we include a 30 day workmanship warranty to guarantee your happiness upon delivery and initial use. We assemble and test every mill we make. If you have any issue, contact us and we will be happy to resolve it!"
        }
      },
      {
        "@type": "Question",
        "name": "Is any assembly required?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "In order to minimize shipping costs, the sides, head bar and legs are removed. Reassembly of your mill is easy and will take just a few minutes."
        }
      },
      {
        "@type": "Question",
        "name": "How does my mill ship?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Our mills ship in a flat box via UPS. Sorry, we cannot ship to P.O. boxes."
        }
      },
      {
        "@type": "Question",
        "name": "How can I purchase a mill?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You can order on our website! Just choose the product you would like to purchase, and follow the prompt. Paypal and all major credit cards are accepted. If you would like to purchase a mill via check or money order, send payment to: Grand Carpet Mill, 2300 W. Dean Road, Temperance, MI. 48182."
        }
      },
      {
        "@type": "Question",
        "name": "Do you ship Internationally, to Alaska, or to Hawaii?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Absolutely! Let us know where you are located via email and we will quote you an International shipping price."
        }
      },
      {
        "@type": "Question",
        "name": "How quickly can I expect my mill?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "It takes 5-8 days to process and ship your order."
        }
      }
    ]
  };
  
  return (
    <div id="faq-wrapper" className="ht-100">
      <Helmet>
        <title>Frequently Asked Questions | Grand Carpet Mill</title>
        <meta name="description" content="Find answers to common questions about Grand Carpet Mill's dog treadmills, shipping, warranty, and more." />
        <meta name="keywords" content="FAQ, Grand Carpet Mill, dog treadmill questions, shipping, warranty, purchase process" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Row className="justify-content-md-center">
        <Col lg="10" md="12" sm="12">
          <Card className="mb-3 products">
            <Accordion>
              <Card>
                <Card.Header>
                  <h5 className="text-cursive pl-3 pt-1 my-0">Frequently Asked Questions</h5>
                </Card.Header>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Is a warranty included with my mill?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    &emsp;We make furniture quality mills - just like the furniture in your living room!
                    As you would expect of quality furniture, we include a 30 day workmanship warranty
                    to guarantee your happiness upon delivery and initial use.
                    We assemble and test every mill we make. If you have any issue, contact us
                    and we will be happy to resolve it!
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Is any assembly required?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    &emsp;In order to minimize shipping costs, the sides, head bar and legs are removed.
                    Reassembly of your mill is easy and will take just a few minutes.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    How does my mill ship?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    &emsp;Our mills ship in a flat box via UPS.  Sorry, we cannot ship to P.O. boxes
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    How can I purchase a mill?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    &emsp;You can order on our website! Just choose the product
                    you would like to purchase, and follow the prompt.
                    Paypal and all major credit cards are accepted.
                    If you would like to purchase a mill via check or money order,
                    send payment to:
                    <p className="font-weight-bold">
                      Grand Carpet Mill
                      {' '}
                      <br />
                      2300 W. Dean Road
                      {' '}
                      <br />
                      Temperance, MI. 48182
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Do you ship Internationally, to Alaska, or to Hawaii?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    &emsp;Absolutely!  Let us know where you are located via email
                    and we will quote you an International shipping price.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="5">
                    How quickly can I expect my mill?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    &emsp;It takes 5-8 days to process and ship your order.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
export default FAQ;
