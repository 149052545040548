import React, { useState } from 'react';
import { Col, Row, Card, Button, Form } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import ProductTable from './ProductTable';
import { useMediaQuery } from '@material-ui/core';
import { productIDs } from '../../../JavaScript/App';
import { OrderSplash } from '../order/orderSplash';
import { productSize, productSizesMap } from '../../../enums/productSizes';
import { LinkContainer } from 'react-router-bootstrap';
import { Helmet } from 'react-helmet';

export const Products = ({ product, cart, setCart, itemId, setItemId, calculateDiscount, calculateTotal }) => {
  const [show, setShow] = useState(false);
  const isSmall = useMediaQuery('(max-width:800px)');
  const [size, setSize] = useState(productSize.Small);
  const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format;

  const addToCart = (productToAdd, sizeId) => {
    if (!productToAdd) {
      productToAdd = { ...product };
    }
    if (!sizeId) {
      sizeId = size;
    }
    if (product) {
      const cartClone = { ...cart };
      let prevProduct = cartClone.Items.find(x => x.ProductID === productToAdd.ProductID && x.SizeID === sizeId);
      if (prevProduct) {
        const items = cart.Items.map(x => {
          if (x.ProductID === productToAdd.ProductID && x.SizeID === sizeId) {
            x.Quantity += 1;
          }
          return x;
        });
        cartClone.Items = items;
        cartClone.Discount = calculateDiscount(items);
        cartClone.Total = calculateTotal(items);
        setCart(cartClone);
      } else {
        const newItems = [...cartClone.Items];
        const cartItemDTO = {
          Name: productToAdd.name,
          Description: productToAdd.description,
          Price: productToAdd.price,
          Quantity: productToAdd.quantity,
          Shipping: productToAdd.shipping,
          ItemID: itemId,
          ProductID: productToAdd.ProductID,
          IsTreadmill: productToAdd.isTreadmill,
          SizeID: sizeId,
        };
        setItemId(itemId + 1);
        newItems.push(cartItemDTO);
        const discount = calculateDiscount(newItems);
        cartClone.Discount = discount;
        cartClone.Items = newItems;
        cartClone.Total = calculateTotal(newItems);
        setCart(cartClone);
      }
      toast.success(`${productToAdd.name} was added to your order!`);
      if (productToAdd.isTreadmill) {
        setShow(true);
      }
    } else {
      toast.error('There was a problem with your order. Please contact us if the problem persists.');
    }
  };

  const structuredData = {
    "@context": "http://schema.org/",
    "@type": "Product",
    "name": product.name,
    "image": product.image,
    "description": product.description,
    "sku": product.ProductID,
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": product.price,
      "availability": "http://schema.org/InStock",
      "url": window.location.href
    }
  };

  return (
    product && (
      <div id="products" className="ht-100">
        <Helmet>
          <title>{product.name} | Grand Carpet Mill</title>
          <meta name="description" content={`Buy the ${product.name} at Grand Carpet Mill. ${product.description}`} />
          <meta name="keywords" content={`${product.name}, Grand Carpet Mill, dog treadmills, pet fitness, ${product.name} details`} />
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
        <ToastContainer
          style={{ marginTop: '5rem' }}
          autoClose={3000}
          draggable={false}
          position="top-right"
        />
        <Card className="mb-3 products" style={{ minHeight: '85vh' }}>
          <h5 className="card-header card-title pl-5">
            {product.name}
          </h5>
          <Row className="no-gutters">
            <Col md="6" sm="12" style={{ marginTop: '1rem' }} className="p-3 text-center">
              <img
                className={product.isTreadmill ? 'card-img img-product img-thumbnail' : 'card-img img-thumbnail img-product-sm'}
                src={product.image}
                alt={`${product.name}`}
                id="product"
              />
              <p className="text-muted">All shipping prices are for the Contiguous U.S.</p>
            </Col>
            <Col md="6" sm="12">
              <div className="card-body">
                <h4 className="card-title text-product-title">
                  {product.name}
                </h4>
                <p style={{ fontSize: '1.25rem', paddingTop: '1rem' }} className="card-text m-0">
                  <span style={{ fontSize: '1.25rem', marginRight: '1rem' }}><u>Description:</u></span>
                  {product.description}
                </p>
                <Row style={{ paddingBottom: '2rem' }}>
                  <Col>
                    {product.isTreadmill && (
                      <small style={{ paddingTop: '1rem' }} className="text-muted">Harness sold separately. Bundle it with your treadmill to receive a discounted rate!</small>
                    )}
                    <p style={{ fontSize: '1.25rem', paddingTop: '1rem' }} className="card-text m-0">
                      <span style={{ fontSize: '1.25rem', marginRight: '1rem' }}>
                        <u>Price:</u>
                        <span className="font-weight-bold" style={{ marginLeft: '.5rem', fontStyle: 'italic', fontSize: '1.2rem' }}>
                          {moneyFormat(product.price)} + {moneyFormat(product.shipping)} shipping
                        </span>
                      </span>
                    </p>
                  </Col>
                </Row>
                <Row>
                  {product.isTreadmill && (
                    <ProductTable
                      price={product.price}
                      height={product.height}
                      width={product.width}
                      weight={product.weight}
                      beltLength={product.beltLength}
                      beltWidth={product.beltWidth}
                    />
                  )}
                </Row>
                {product.ProductID === productIDs.WorkingHarness && (
                  <Col>
                    <Form.Group as={Row}>
                      <Form.Label style={{ marginRight: '.5rem', marginTop: '.5rem' }}>Size</Form.Label>
                      <Form.Control as="select" value={size} style={{ width: '8rem' }} onChange={(e) => setSize(e.target.value)}>
                        <option value={productSize.Small}>{productSizesMap[productSize.Small]}</option>
                        <option value={productSize.Medium}>{productSizesMap[productSize.Medium]}</option>
                        <option value={productSize.Large}>{productSizesMap[productSize.Large]}</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                )}
                <Row style={{ marginTop: '1rem', marginLeft: isSmall ? '3rem' : 'auto', fontSize: isSmall ? '.5rem' : '1rem' }}>
                  <Col style={{ display: 'flex', flexDirection: isSmall ? 'row-reverse' : 'row' }}>
                    <Button
                      style={{ marginRight: '1rem', height: isSmall ? '2.1rem' : '2.5rem', fontSize: isSmall ? '.8rem' : '1rem' }}
                      onClick={() => product.ProductID === productIDs.WorkingHarness ? addToCart(product, size) : addToCart(product)}
                    >
                      Add To Cart
                    </Button>
                    <LinkContainer to="Checkout" style={{ color: 'white', fontSize: isSmall ? '.8rem' : '1rem', height: isSmall ? '2.1rem' : '2.5rem' }}>
                      <Button variant="warning" className={isSmall ? 'ml-auto mr-2' : ''}>Checkout</Button>
                    </LinkContainer>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
        <OrderSplash
          show={show}
          setShow={setShow}
          addToCart={addToCart}
        />
      </div>
    )
  );
};