import React from 'react';
import { Row, Col, Carousel } from 'react-bootstrap';
import HealthyDog1 from '../../images/Home Page Pictures/Healthy Dog 1 (widescreen).jpg';
import HealthyDog3 from '../../images/Home Page Pictures/Healthy Dog 3 (widescreen).jpg';
import HealthyDog4 from '../../images/Home Page Pictures/Healthy Dog 4 (widescreen).jpg';
import { useMediaQuery } from '@material-ui/core';
import { Helmet } from 'react-helmet';

const structuredData = {
  "@context": "https://schema.org",
  "@type": "Store",
  "name": "Grand Carpet Mill",
  "description": "Grand Carpet Mill is a local shop specializing in the manufacturing of high-quality dog treadmills.",
  "url": "https://www.grandcarpetmill.com",
  "telephone": "+1-734-847-3759",
  "email": "piston111@msn.com",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "2300 W Dean Rd",
    "addressLocality": "Temperance",
    "addressRegion": "MI",
    "postalCode": "48182",
    "addressCountry": "US"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": "41.76886",
    "longitude": "-83.59999"
  },
  "openingHoursSpecification": [
    {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday"
      ],
      "opens": "09:00",
      "closes": "18:00"
    }
  ],
  "founder": {
    "@type": "Person",
    "name": "Kelly Young"
  },
  "priceRange": "$$$",
  "paymentAccepted": "Cash, Credit Card, Debit Card, PayPal",
  "currenciesAccepted": "USD",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+1-734-847-3759",
    "contactType": "Customer Service",
    "areaServed": "US",
    "availableLanguage": "English"
  }
};

function Home() {
  const isSmall = useMediaQuery('(max-width:525px)');
  return (
    <div id="home-wrapper" className="ht-100 d-flex align-items-center justify-content-center">
      <Helmet>
        <title>Grand Carpet Mill - Home</title>
        <meta name="description" content="Welcome to Grand Carpet Mill, your premier source for dog treadmills." />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Row className="w-100" style={{paddingTop: isSmall ? '5rem' : 'inherit'}}>
        <Col lg="12" className="mx-auto">
          <Carousel fade>
            <Carousel.Item>
              <img
                className="d-block w-100 img-fluid"
                src={HealthyDog1}
                alt="Healthy dog running on a treadmill"
                style={{height: isSmall ? '24rem' : 'auto'}}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 img-fluid"
                src={HealthyDog4}
                alt="Dog enjoying exercise on a treadmill"
                style={{height: isSmall ? '24rem' : 'auto'}}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 img-fluid"
                src={HealthyDog3}
                alt="Happy dog on a treadmill"
                style={{height: isSmall ? '24rem' : 'auto'}}
              />
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </div>
  );
}

export default Home;