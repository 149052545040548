import React from 'react';
import { Card, Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';

import PugVideo from '../../../videos/Pug review.mp4';
import ChiwawaVideo from '../../../videos/Chiwawa review.mp4';
import DobermanVideo from '../../../videos/Doberman.mp4';

const corgiVideoURL = 'https://www.youtube.com/watch?v=ouy8WprzGBQ';
const millReviewVideo = 'https://www.youtube.com/watch?v=z4uYcvHuPak';

const Testimonials = () => {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "Grand Carpet Mill",
    "description": "Hear what our customers are saying about Grand Carpet Mill's dog treadmills. Watch reviews and read testimonials from happy customers.",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "reviewCount": "6",
      "bestRating": "5",
      "worstRating": "1"
    },
    "review": [
      {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Mike"
        },
        "reviewBody": "Hi, I just received the treadmill this week, the pup loves it. He took to it after just a couple days... Thanks for a great product!",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5",
          "worstRating": "1"
        }
      },
      {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Erin Kramer"
        },
        "reviewBody": "I just wanted to drop you guys a line as a recent purchaser of the Grand Deluxe Mill to let you know how pleased we are with our purchase. We run a dog training/behavior rehabilitation center in Sacramento, CA. area and we focus on difficult cases such as dogs with serious fear or aggression issues as well as training sport and K9 dogs. We have been using your treadmill as part of our training process; from our working dogs, to our higher energy pups with great results!",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5",
          "worstRating": "1"
        }
      },
      {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Greg"
        },
        "reviewBody": "It was a snap to set up, and my dog has taken to it like a duck in water! I was expecting a month or longer teaching her how to run on it, but I did not need to. She can start and stop it on her own, run full speed, or easy trot in less than 3 days...outstanding! Thank you so much for a wonderful product.",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5",
          "worstRating": "1"
        }
      },
      {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Kristine Gunter"
        },
        "reviewBody": "My mill arrived today and my Corgi loves it! Here is a video of her on it just minutes after I finished assembling it.",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5",
          "worstRating": "1"
        }
      },
      {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Jess"
        },
        "reviewBody": "What an awesome product. I have a 4 month old American Pit Bull Terrier and I was concerned that he might not take to it very well. It wasn't 5 minutes until he was at full speed. I am certainly going to tell all of my friends about this!",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5",
          "worstRating": "1"
        }
      },
      {
        "@type": "Review",
        "author": {
          "@type": "Person",
          "name": "Amanda"
        },
        "reviewBody": "Koda now gets on by herself when she wants to run, I didn't know that she was going to enjoy this treadmill so much.",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5",
          "worstRating": "1"
        }
      }
    ]
  };

  return (
    <div className="ht-100">
      <Helmet>
        <title>Customer Testimonials | Grand Carpet Mill</title>
        <meta name="description" content="Hear what our customers are saying about Grand Carpet Mill's dog treadmills. Watch reviews and read testimonials from happy customers." />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Row className="justify-content-md-center">
        <Col lg="10" md="12" sm="12">
          <Card>
            <Card.Header>
              <h5 className="text-cursive pl-3 pt-1 my-0">Don't just take it from us, hear what our customers are saying!</h5>
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroupItem>
                <blockquote className="blockquote mb-0">
                  <p>
                    "Hi, I just received the treadmill this week, the pup loves it.
                    He took to it after just a couple days. . .
                    Thanks for a great product!"
                  </p>
                  <footer className="blockquote-footer text-right">
                    Mike
                    <cite> Happy Customer</cite>
                  </footer>
                </blockquote>
                <ReactPlayer
                  url={PugVideo}
                  controls
                  className="mx-auto react-player col-12 pt-3"
                  id="pug-video"
                  loop
                />
              </ListGroupItem>
              <ListGroupItem>
                <blockquote className="blockquote mb-0">
                  <p>
                    "I just wanted to drop you guys a line as a recent purchaser of the
                    Grand Deluxe Mill to let you know how pleased we are with our purchase.
                    We run a dog training/behavior rehabilitation center in Sacramento, CA.
                    area and we focus on difficult cases such as dogs with serious fear or aggression
                    issues as well as training sport and K9 dogs. We have been using your treadmill
                    as part of our training process; from our working dogs,
                    to our higher energy pups with great results!"
                  </p>
                  <footer className="blockquote-footer">
                    Erin Kramer
                    <cite> Owner of Tug Dogs. Sacramento, CA.</cite>
                  </footer>
                </blockquote>
              </ListGroupItem>
              <ListGroupItem>
                <ReactPlayer
                  url={millReviewVideo}
                  controls
                  className="mx-auto react-player col-12 pt-3"
                  id="review-video"
                  loop
                />
              </ListGroupItem>
              <ListGroupItem>
                <blockquote className="blockquote mb-0">
                  <p>
                    "It was a snap to set up, and my dog has taken to it like a duck in water!
                    I was expecting a month or longer teaching her how to run on it, but I did not
                    need to. She can start and stop it on her own, run full speed, or easy trot in
                    less than 3 days...outstanding! Thank you so much for a wonderful product."
                  </p>
                  <footer className="blockquote-footer text-right">
                    Greg
                    <cite> Happy Customer</cite>
                  </footer>
                </blockquote>
              </ListGroupItem>
              <ListGroupItem>
                <blockquote className="blockquote mb-0">
                  <p>
                    "My mill arrived today and my Corgi loves it! Here is a video
                    of her on it just minutes after I finished assembling it."
                  </p>
                  <footer className="blockquote-footer">
                    Kristine Gunter
                    <cite> Author of "The Corgi Chronicles"</cite>
                  </footer>
                </blockquote>
                <ReactPlayer
                  url={corgiVideoURL}
                  controls
                  className="mx-auto react-player col-12 pt-3"
                  id="corgi-video"
                  loop
                />
              </ListGroupItem>
              <ListGroupItem>
                <blockquote className="blockquote mb-0">
                  <p>
                    "What an awesome product. I have a 4 month old American Pit Bull Terrier
                    and I was concerned that he might not take to it very well. It wasn't
                    5 minutes until he was at full speed. I am certainly going to tell all of
                    my friends about this!"
                  </p>
                  <footer className="blockquote-footer text-right">
                    Jess
                    <cite> Happy Customer</cite>
                  </footer>
                </blockquote>
              </ListGroupItem>
              <ListGroupItem>
                <ReactPlayer
                  url={ChiwawaVideo}
                  controls
                  className="mx-auto react-player col-12 pt-3"
                  id="chiwawa-video"
                  loop
                />
              </ListGroupItem>
              <ListGroupItem>
                <blockquote className="blockquote mb-0">
                  <p>
                    "Koda now gets on by herself when she wants to run, I didn't know that she was going to enjoy this treadmill so much."
                  </p>
                  <footer className="blockquote-footer text-left">
                    Amanda
                    <cite> Happy Customer</cite>
                  </footer>
                </blockquote>
              </ListGroupItem>
              <ListGroupItem>
                <ReactPlayer
                  url={DobermanVideo}
                  controls
                  className="mx-auto react-player col-12 pt-3"
                  id="doberman-video"
                  loop
                />
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Testimonials;