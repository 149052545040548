import React from 'react';
import CreditCards from '../../images/credit_cards.jpg';

const currentYear = new Date().getFullYear();

const Footer = () => (
  <footer id="footer" className="row contact-row mx-auto mt-3">
    <div className="col-12 text-center company-text">
      <span className="pr-2">
        © {currentYear} | Grand Carpet Mill
      </span>
      <img
        src={CreditCards}
        alt="Logos of accepted credit cards: Visa, MasterCard, American Express, and Discover"
        className="credit-card img-fluid inline-block"
        title="Accepted Payment Methods"
      />
      <div style={{ overflow: 'hidden', display: 'inline', marginLeft: '1rem' }}>
        <span className="text-right text-nowrap company-text">Call us today! 734-847-3759</span>
      </div>
    </div>
  </footer>
);

export default Footer;