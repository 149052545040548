import React, { useEffect, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import initalCart from '../../../JavaScript/utility';
import { productSizesMap } from '../../../enums/productSizes';
import { productIDs } from '../../../JavaScript/App';

export const PayPalButton = ({ cart, setCart, setHasSuccessfulOrderPlaced }) => {
    let paypal = useRef(null);

    const getCartTotal = () => {
        let unitAmount = 0;
        cart && cart.Items && cart.Items.map(x => {
            unitAmount += (x.Quantity * x.Price) + (x.Quantity * x.Shipping);
        });
        unitAmount -= cart.Discount;
        return Math.max(unitAmount, 0);
    };

    useEffect(() => {
        if (cart) {
            const loadedPaypalButton = document.getElementsByClassName('paypal-buttons')[0];
            if (loadedPaypalButton) {
                loadedPaypalButton.remove();
            }
            window.paypal.Buttons({
                createOrder: (data, actions, err) => {
                    let shippingFee = 0;
                    let unitAmountSum = 0;
                    const items = cart.Items.map(x => {
                        shippingFee += (x.Quantity * x.Shipping);
                        unitAmountSum += (x.Quantity * x.Price);
                        return {
                            "name": `${x.Name}${x.ProductID === productIDs.WorkingHarness ? " (" + productSizesMap[x.SizeID] + ")" : ''}`,
                            "description": x.Description,
                            "unit_amount": {
                                "currency_code": "USD",
                                "value": (x.Price).toFixed(2) 
                            },
                            "quantity": `${x.Quantity}`
                        };
                    });
                    const request = [{
                        "amount": {
                            "currency_code": "USD",
                            "value": cart.Total.toFixed(2),
                            "breakdown": {
                                "item_total": {
                                    "currency_code": "USD",
                                    "value": unitAmountSum.toFixed(2)
                                },
                                "shipping": {
                                    "value": shippingFee.toFixed(2),
                                    "currency_code": "USD",
                                },
                                "discount": {
                                    "currency_code": "USD",
                                    "value": cart.Discount.toFixed(2)
                                }
                            }
                        },
                        "items": items
                    }];
                    return actions.order.create({
                        "purchase_units": request
                    });
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture();
                    console.log(order);
                    toast.success('Your Order has been placed. Thank you!');
                    setHasSuccessfulOrderPlaced(true);
                    setTimeout(() => setCart(initalCart), 500);
                },
                onError: (err) => {
                    toast.error('There was a problem placing your order. Please contact us if the problem persists.');
                }
            }).render(paypal.current);
        }
    }, [cart]);

    return (
        <div>
            <div ref={paypal}></div>
            <div data-pp-message
                data-pp-amount={getCartTotal()}>
            </div>
            <ToastContainer
                style={{ marginTop: '5rem' }}
                autoClose={3000}
                draggable={false}
                position={'top-right'}
            />
        </div>
    );
};