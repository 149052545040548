/* eslint-disable*/


/* eslint-disable no-param-reassign */
const cartReducer = (state = [], action) => {
  switch (action.type) {
    case 'ADD_TO_CART': {
      if (state.length === 0) {
        state = [];
        state.push(action.payload);
        return state;
      }

      if (state.find(product => product.name == action.payload.name)) {
        return state.map((product) => {
          if (product.name === action.payload.name) {
            return { ...product, quantity: product.quantity + action.payload.quantity };
          }
           return product;
        });
      }
      else{
        const cart = state.map((product) => {
          return product;
        });
        cart.push(action.payload);
        return cart;
      }
    }
    case 'REMOVE_FROM_CART':
      if (state.length === 0) {
        return state; // new state, might be able to be removed
      }
      return state.map((product) => {
        if (product.name !== action.payload.name) {
          return { ...product }; // This might work, but could use a filter to get it
        }
      });
    default:
      return state;
  }
};
export default cartReducer;
