import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const MissionStatement = () => {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Grand Carpet Mill",
    "url": "http://www.grandcarpetmill.com",
    "logo": "http://www.grandcarpetmill.com/logo.png",
    "description": "Discover the mission of Grand Carpet Mill, the originator of the portable dog exerciser, committed to quality, value, and customer satisfaction since 1986.",
    "founder": {
      "@type": "Person",
      "name": "Kelly",
      "jobTitle": "Owner of Grand Carpet Mill"
    },
  };

  return (
    <div id="MissionStatement" className="ht-100">
      <Helmet>
        <title>Our Mission | Grand Carpet Mill</title>
        <meta name="description" content="Discover the mission of Grand Carpet Mill, the originator of the portable dog exerciser, committed to quality, value, and customer satisfaction since 1986." />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Row className="justify-content-md-center">
        <Col lg="10" md="12" sm="12">
          <Card id="card-fill">
            <Card.Header>
              <h5 className="text-cursive pl-3 pt-1 my-0">Our Mission</h5>
            </Card.Header>
            <Card.Body>
              <blockquote>
                <p>
                  &emsp;Grand Carpet Mill is a worldwide sales business,
                  and the sole originator of the portable dog exerciser.
                  Often copied but never equaled in quality, value, or design.
                  Established in 1986, Grand Carpet Mill is a registered trademark
                  and business in the state of Michigan, county of Monroe, D.B.A. Grand Carpet Mill.
                  Our mills feature functional design techniques aimed at satisfying
                  a wide range of users while maintaining affordability.
                  Design techniques that are engineered into the mill to increase
                  overall effectiveness and structural strength through design, not bulk.
                  Quality products, affordable prices, and customer satisfaction
                  will always be our goal. Join our worldwide satisfied customers
                  and order yours today!
                </p>
                <footer className="blockquote-footer text-right font-larger">
                  Kelly
                  <cite> Owner of Grand Carpet Mill</cite>
                </footer>
              </blockquote>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MissionStatement;