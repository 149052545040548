import React from 'react'
import { Toast } from 'react-bootstrap'

// This needs to have error and success capabilities (Possibly pass error, success and just set the color would be an easy route.)
export const Toasty = ({toasts, setToasts}) => {

    const removeToast = (k) => {
        const clonetoasts = toasts.map(x => x).filter(x => x.key !== k)
        setToasts(clonetoasts)
    }

    return (
        <div>
            {toasts.map((x) => {
                const {title, message} = x
                return <Toast key={x.key} onClose={() => removeToast(x.key)} show delay={5000} autohide={true} style={{minWidth: '200px'}}>
                    <Toast.Header style={{backgroundColor: '#eeeeff'}}>
                        <strong>{title}</strong>
                    </Toast.Header>
                    <Toast.Body>
                        {message}
                    </Toast.Body>
                </Toast>
            })}
        </div>
    )
}