import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

const ProductTable = ({ height, width, weight, beltLength, beltWidth }) => (
  <Table striped responsive hover size="sm" aria-label="Product specifications table">
    <thead className="text-center">
      <tr>
        <th scope="col">Height</th>
        <th scope="col">Width</th>
        <th scope="col">Weight</th>
        <th scope="col">Belt Length</th>
        <th scope="col">Belt Width</th>
      </tr>
    </thead>
    <tbody className="text-center">
      <tr>
        <td>{height}</td>
        <td>{width}</td>
        <td>{weight}</td>
        <td>{beltLength}</td>
        <td>{beltWidth}</td>
      </tr>
    </tbody>
  </Table>
);

ProductTable.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  beltLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  beltWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ProductTable.defaultProps = {
  height: 'N/A',
  width: 'N/A',
  weight: 'N/A',
  beltLength: 'N/A',
  beltWidth: 'N/A',
};

export default ProductTable;